import React, { useState, useEffect } from 'react';
import './ConfirmPurchase.css';
import { useLocation, useNavigate } from 'react-router-dom';

let cancel;

function ConfirmPurchase() {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const receivedData =  searchParams.get('data');
  const jsonData = JSON.parse(receivedData);
  const {state} = useLocation();
  const navigate = useNavigate(); 
  const [showForm, setShowForm] = useState(false); // Nuevo estado para mostrar el formulario
  const [formData, setFormData] = useState({
      url: '',
      token: '',
    });

    
    // Código para redirigir a WEBPAY
    useEffect(() => {
      const FetchWebPay = async () => {
        try {
          if (cancel) {
            cancel('Request canceled');
          }
          setFormData(state);
          setShowForm(true);
        }
        catch (error) {
          console.log(error);
        }
      };
      FetchWebPay();
  }, [state]);

  const handleForm = () => {
    navigate(`/purchase/${jsonData.flight_id}`);
  };
  
  return (
    <div>
      <p style={{textAlign: 'center'}}>¿Estás seguro de que quieres comprar {jsonData.cantidad} ticket(es) del vuelo desde {jsonData.departure} a {jsonData.arrival} al precio total de {jsonData.precioTotal} CLP?</p>
      <div style={{alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'row'}}>
        <form method="post" action={''} onSubmit={handleForm} style={{ margin: '10px',textAlign: 'center' }}>
          <input className="blue-background-white-text" type="submit" value="Cancelar Compra" />
        </form>
        {showForm ? (
          <form method="POST" action={formData.url} style={{ margin: '10px',textAlign: 'center' }}>
          <input type="hidden" name="token_ws" value={formData.token} />
          {/* <span>URL: {formData.url} </span>
          <br />
          <span>Token: {formData.token} </span> */}
          <input className="blue-background-white-text" type="submit" value="Confirmar Compra" />
        </form>
        ) : null}
      </div>
    </div>
  );
};

export default ConfirmPurchase;
