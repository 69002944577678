import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './FlightPurchase.css';
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const backendURL = process.env.REACT_APP_BACKEND_URL;
const CancelToken = axios.CancelToken;
let cancel;

function FlightPurchase() {
  const navigate = useNavigate();
  const { loginWithRedirect, user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [flightArray, setFlightArray] = useState([]);
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const page = searchParams.get('page') || 1;
  const size = searchParams.get('size') || 5;
  const [currentFlight, setCurrentFlight] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [roles, setRoles] = useState([]);

  const [currentPage] = useState(1);

  useEffect(() => {
    const fetchRoles = () => {
      const rolesNamespace = 'http://myapp.com/roles';
      const userRoles = user && user[rolesNamespace] ? user[rolesNamespace] : [];
      setRoles(userRoles);
    };
    if (user) {
      fetchRoles();
    }
  }, [user]);

  useEffect(() => {
    const getAccessToken = async () => {
      if (isAuthenticated) {
        const accessToken = await getAccessTokenSilently();
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      }
    };
    getAccessToken();
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (flightArray.length > 0) {
      setCurrentFlight(flightArray[0]);
    }
  }, [flightArray]);

  const currentItems = flightArray.slice(
    (currentPage - 1) * size,
    currentPage * size
  );

  const handleQuantityChange = (event) => {
    const selectedQuantity = parseInt(event.target.value, 10);
    setQuantity(selectedQuantity);
  };

  const handleClick = () => {
    buyTicket();
  };

  const buyTicket = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${accessToken}` };
      if (!roles.includes('admin')) {
        // Show error message because only admins can buy tickets for group 23
        alert('Only admins con buy tickets!.');
        return;
      }
      const seller = 23;
      axios.post(`${backendURL}/tickets/`,
        {
          departure_airport_id: currentFlight.departure_airport_id,
          arrival_airport_id: currentFlight.arrival_airport_id,
          user_id: user.sub,
          flight_id: currentFlight.id,
          time_departure: currentFlight.time_departure,
          datetime: currentFlight.datetime,
          seller: seller,
          price: currentFlight.price,
          status: "pending",
          amount: quantity,
          role: roles.includes('admin') ? 'admin' : 'user'
        },
        { headers
          ,
          withCredentials: true,
         }).then((response) => {

          const data = {
            flight_id: currentFlight ? currentFlight.id : '',
            departure: currentFlight ? currentFlight.departure_airport_id : '',
            arrival: currentFlight ? currentFlight.arrival_airport_id : '',
            cantidad: quantity,
            precioTotal: currentFlight ? currentFlight.price * quantity : 0,
          };
          console.log(response.data);
          navigate('/confirmarcompra?data=' + JSON.stringify(data),
            { state: { token: response.data.token, url: response.data.url } });
    });
    } catch (error) {
      if (error.message === "Login required") {
        alert('Por favor, inicie sesión para realizar la compra.');
        loginWithRedirect();
        return;
      }
      console.error('Error al realizar la compra:', error);
      alert('Hubo un error al realizar la compra. Por favor, inténtelo de nuevo más tarde.');
    }
  };

  useEffect(() => {
    if (flightArray.length === 0) {
      const GetFlight = async () => {
        const url = `${backendURL}/flights/${id}`;
        try {
          console.log(id)
          if (cancel) {
            cancel('Request canceled');
          }
          const respuesta = await axios.get(url, {
            cancelToken: new CancelToken(function executor(c) {
              cancel = c;
            }),
          });
          setFlightArray(respuesta.data);
          console.log(respuesta.data);

        } catch (error) {
          console.error('Error al obtener las existencias:', error);
        }
      };
      GetFlight();
    }
  }, [id, page, size, flightArray]);

  const goBackToFlights = () => {
    navigate('/search');
  };

  return (
    <main className="content">
      <div className="bg-container"></div>
      <div className="content-landing">
        <button onClick={goBackToFlights}>
          Volver
        </button>

        <h1>Welcome to <span className="name-landing">Flight Purchase</span></h1>
        <div className="translucent-grey-rounded">

          <h2>Flight Details</h2>
          <h3>Departure Airport: {currentFlight ? currentFlight.departure_airport_id : ''}</h3>
          <h3>Arrival Airport: {currentFlight ? currentFlight.arrival_airport_id : ''}</h3>
          <h3>Departure Time: {currentFlight ? currentFlight.time_departure : ''}</h3>
          <label htmlFor="optionSelector">Choose an option:</label>
          <select id="optionSelector" name="option" onChange={handleQuantityChange} >
            <option value="1">1 ticket</option>
            <option value="2">2 tickets</option>
            <option value="3">3 tickets</option>
            <option value="4">4 tickets</option>
            <option value="5">5 tickets</option>
            <option value="6">6 tickets</option>
            <option value="7">7 tickets</option>
            <option value="8">8 tickets</option>
            <option value="9">9 tickets</option>
            <option value="10">10 tickets</option>
          </select>

          {currentItems.map((flight, index) => (
            <h4 style={{ textAlign: 'center' }} key={index}>
              {flight.price * quantity} {flight.currency}
            </h4>
          ))}

          {/* <label>Buy Ticket(s):</label>
          <button onClick={handleClick}>
            Buy Ticket
          </button> */}
          <label>{roles.includes('admin') ? 'Buy Ticket(s) for Group 23:' : 'Buy Ticket(s):'}</label>
          <button onClick={handleClick}>
            {roles.includes('admin') ? 'Buy Ticket for Group 23' : 'Buy Ticket'}
          </button>
        </div>
      </div>
    </main>
  );
}

export default FlightPurchase;
