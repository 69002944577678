import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './PurchaseComplete.css';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';


const backendURL = process.env.REACT_APP_BACKEND_URL;
let cancel;

function PurchaseComplete() {

    const location = useLocation();
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [ip, setIp] = useState(null);
    const [address, setAddress] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [purchaseStatus, setPurchaseStatus] = useState(null);
    const [flight_id, setFlightId] = useState(null);
    const [ticket_id, setTicketId] = useState(null);
    const [amount, setAmount] = useState(null);
    const [flight, setFlight] = useState(null);
    const [role, setRole] = useState(null);
    
    if (isAuthenticated) {
      getAccessTokenSilently().then((accessToken) => {
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      });
    }

    useEffect(() =>{
        const fetchUserAddress = async () => {
          try {
            if (!user) {
              return;
            }
            const response = await axios.get('https://ipapi.co/json/')
            const response2 = await axios.get(`https://ipapi.co/${response.data.ip}/json/`);
            await axios.post(`${backendURL}/geolocation/`, 
            {"user_id": user.sub, "latitude": response2.data.latitude, "longitude": response2.data.longitude});
            setIp(response.data.ip);
            setAddress(response2.data);
            setIsLoading(false);
          } catch (error) {
            console.error('Error fetching user IP:', error);
          }
        };
  fetchUserAddress();
  }, [user]);
  
  useEffect(() => {
    const GetPurchaseStatus = async () => {
      try {
        if (cancel) {
          cancel('Request canceled');
        }
        if (!user) {
          return;
        }
        const searchParams = new URLSearchParams(location.search);
        setFlightId(searchParams.get('flight_id'));
        setTicketId(searchParams.get('ticket_id'));
        setAmount(searchParams.get('amount'));
        setRole(searchParams.get('role'));
        var sessionId = ""
        const token = searchParams.get('token_ws');
        if (!token) {
          sessionId = searchParams.get('TBK_ID_SESION');
        }
        const mail = user.email
        const user_id = user.sub;
        if (role === 'admin') {
          const respuesta = await axios.post(`${backendURL}/adminpurchases`,
          { token_ws: token, mail: mail, user_id: user_id, session_id: sessionId});
          setPurchaseStatus(respuesta.data["message"]);      
          setIsLoading(false);
        }
        else {
          const respuesta = await axios.post(`${backendURL}/purchases`, 
          { token_ws: token, mail: mail, user_id: user_id, session_id: sessionId});
          setPurchaseStatus(respuesta.data["message"]);      
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error al obtener las existencias:', error);
      }
    }
    GetPurchaseStatus();
  }, [user, location.search, role]);

  useEffect(() => {
    const GetFlight = async () => {
        if (cancel) {
          cancel('Request canceled');
        }
        if (purchaseStatus !== "Transaction confirmed") {
          return;
        };

        const url = `${backendURL}/flights/${flight_id}`;
        try {
          const respuesta = await axios.get(url, {
            
          withCredentials: true,
          });
          setFlight(respuesta.data);
          console.log(respuesta.data);
        } catch (error) {
          console.error('Error al obtener el vuelo:', error);
        }
      } 
      GetFlight();
  }, [purchaseStatus, flight_id] );

  // useEffect(() => {
  //   const GetTicket = async () => {
  //     if (cancel) {
  //       cancel('Request canceled');
  //     }
  //     try {
  //       // Set ticket after respone, use then
  //       await axios.get(`${backendURL}/tickets/${ticket_id}`).then((response) => {

  //         console.log(response.data);
  //         setTicket(response.data);
  //       });
  //     }
  //     catch (error) {
  //       console.error('Error al obtener el ticket:', error);
  //     }
  //   }
  //   GetTicket();
  // }, [ticket_id] );

  const GeneratePdf = async () => {
      try {
        if (cancel) {
          cancel('Request canceled');
        }
                // if (!ticket) {
        //   return;
        // }
        if (!flight) {
          return;
        }
        const flight_info = flight[0];
        axios.post(`${backendURL}/receipt/`,
        {
          "name": user.name,
          "flight_number": flight_info.id,
          "departure": flight_info.departure_airport_id,
          "arrival": flight_info.arrival_airport_id,
          "seat": "1A",
          "price": flight_info.price * amount,
          "ticket_id": ticket_id
        }
        ).then((pdf_response) => {
          

          console.log(pdf_response.data);

          const byteCharacters = atob(pdf_response.data.body);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'application/pdf' });
          const blobUrl = URL.createObjectURL(blob);
            // Create a Blob from the PDF

          // Create a link element to download the Blob
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = 'document.pdf';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      } catch (error) {
        console.error('Error al obtener las existencias:', error);
      }
    };

  if (isLoading) {
    return <p>Loading...</p>;
  }

    return (
        <main className="content">
        <div className="bg-container"></div>
        <div className="content-landing"></div>
        <div>
        <h1><span className="name-landing">{purchaseStatus}</span></h1>
        {ip ? (
          <div>
            <h4>IP: {ip}</h4>
            <h4>Address: {address.city}, {address.region}, {address.country}</h4>
            <h4>Location: {address.longitude}, {address.latitude}</h4>
            {/* Button to download receipt pdf */}
            <button onClick={() => GeneratePdf()}>Download Receipt</button>
          </div>
        ) : (
          <div>
            <h4>IP: No IP found</h4>
            <h4>Address: No address found</h4>
            <h4>Location: No location found</h4>
          </div>
        )}
        
        </div>
        </main>
    );
}

export default PurchaseComplete;