import React, { useState } from "react";
import axios from 'axios';
import { NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from "../../profile/Logout";
import LoginButton from "../../profile/Login";

const backendURL = process.env.REACT_APP_BACKEND_URL;


function Navbar() {
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const { isAuthenticated, user } = useAuth0();

  

  const handleButtonClick = () => {
    // Make an Axios call to the backend
    axios.get(`${backendURL}/workers`)
      .then(response => {
        // Set the message based on the response
        const working = response.data.working;
        setMessage(working ? 'The worker is working!' : 'The worker is not working!');
        setShowMessage(true);

        // Hide the message after 5 seconds
        setTimeout(() => {
          setShowMessage(false);
        }, 5000);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  return (
    <header>
      <nav className="navbar">
        <NavLink to="/" className="logo-display">
          <span className="name title"> Flight Booker </span>
        </NavLink>
        <ul className="navbar-links-container">
          <li className="navbar-element">
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? "navbar-link name" : "navbar-link"
              }
            >
              Inicio TEST
            </NavLink>
          </li>
          <li className="navbar-element">
            <NavLink
              to="search"
              className={({ isActive }) =>
                isActive ? "navbar-link name" : "navbar-link"
              }
            >
              Flight Search
            </NavLink>
          </li>
            <li className="navbar-element">
            <NavLink to="/adminflights" className="navbar-link">
              Admin Flights Search
            </NavLink>
          </li>
          {isAuthenticated ? (
  <>
    <li className="navbar-element">
      <NavLink to="/profile" className="navbar-link">
        Perfil ({user.nickname})
      </NavLink>
    </li>
    <li className="navbar-element">
      <NavLink to="/mytickets" className="navbar-link">
        My Tickets
      </NavLink>
    </li>
    <li className="navbar-element">
      <NavLink to="/topflightrecommendations" className="navbar-link">
        My Top Flight Recommendations
      </NavLink>
    </li>
    <li className="navbar-element">
      <NavLink to="/auctions" className="navbar-link">
        Auctions
      </NavLink>
    </li>
    <li className="navbar-element">
            <button onClick={handleButtonClick} className="heart-button">
              ❤️
            </button>
            {/* Display the message */}
            {showMessage && (
              <span className="message">{message}</span>
            )}
          </li>
    <li className="navbar-element">
      <LogoutButton />
    </li>
  </>
) : (
  <li className="navbar-element">
    <LoginButton />
  </li>
)}
        </ul>
      </nav>
    </header>
  );
}

export default Navbar;

