import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './AdminFlightPurchase.css';
import {  useParams, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const backendURL = process.env.REACT_APP_BACKEND_URL;
const CancelToken = axios.CancelToken;
let cancel;
axios.defaults.withCredentials = true;  // Asegúrate de que Axios envíe credenciales


function AdminFlightPurchase() {
  const navigate = useNavigate();
  const { loginWithRedirect, user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { id } = useParams();
  const [currentTicket, setcurrentTicket] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [roles, setRoles] = useState([]);
  const [discountPercentage, setDiscountPercentage] = useState(10);  // Ejemplo de porcentaje de descuento

  useEffect(() => {
    const fetchRoles = () => {
      const rolesNamespace = 'http://myapp.com/roles';
      const userRoles = user && user[rolesNamespace] ? user[rolesNamespace] : [];
      setRoles(userRoles);
    };
    if (user) {
      fetchRoles();
    }
  }, [user]);

  useEffect(() => {
    const getAccessToken = async () => {
      if (isAuthenticated) {
        const accessToken = await getAccessTokenSilently();
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      }
    };
    getAccessToken();
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    const fetchFlightDetails = async () => {
    const url = `${backendURL}/flights/${id}`;
    try {
      console.log(id)
      if (cancel) {
        cancel('Request canceled');
      }
      const response = await axios.get(url, {
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
        
          withCredentials: true,
      });
      // If ticket is already set then don't update it
      if (!currentTicket) {
        setcurrentTicket(response.data[0]);
        console.log(response.data[0]);
      }
      console.log(response.data); 
    } catch (error) {
      console.error('Error al obtener los detalles del vuelo:', error);
    }
  };
  fetchFlightDetails();
  }, [id, currentTicket]);

  const handleQuantityChange = (event) => {
    const selectedQuantity = parseInt(event.target.value, 10);
    setQuantity(selectedQuantity);
  };

  const handleClick = () => {
    if (roles.includes('admin')) {
      // Show error message because only admins can apply discounts
      alert('Cant buy your own tickets!.');
      return;
    };
    buyTicket();
  };
  
  const applyDiscount = async () => {
    try {
      if (!roles.includes('admin')) {
        // Show error message because only admins can apply discounts
        alert('Only admins can apply discounts!.');
        return;
      };
      currentTicket.price = currentTicket.price - (currentTicket.price * discountPercentage / 100);
      // Refresh the price in the UI
      setcurrentTicket({ ...currentTicket }); // Refresh the state to trigger a re-render and update the price in the UI


      alert('Descuento aplicado con éxito');
      } catch (error) {
        console.error('Error al aplicar el descuento:', error);
        alert('Hubo un error al aplicar el descuento. Por favor, inténtelo de nuevo más tarde.');
      }
  };

  const buyTicket = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${accessToken}` };
      const seller = roles.includes('admin') ? 23 : 0;

      const response = await axios.post(`${backendURL}/tickets/`,
        {
          user_id: user.sub,
          departure_airport_id: currentTicket.departure_airport_id,
          arrival_airport_id: currentTicket.arrival_airport_id,
          flight_id: currentTicket.id,
          time_departure: currentTicket.time_departure,
          datetime: currentTicket.datetime,
          price: currentTicket.price,
          seller: seller,
          status: "pending",
          amount: quantity,
          role: roles.includes('admin') ? 'admin' : 'user'
        },
        { headers });

      const data = {
        flight_id: currentTicket ? currentTicket.id : '',
        departure: currentTicket ? currentTicket.departure_airport_id : '',
        arrival: currentTicket ? currentTicket.arrival_airport_id : '',
        cantidad: quantity,
        precioTotal: currentTicket ? currentTicket.price * quantity : 0,
      };
      navigate('/confirmarcompra?data=' + JSON.stringify(data),
        { state: { token: response.data.token, url: response.data.url } });
    } catch (error) {
      if (error.message === "Login required") {
        alert('Por favor, inicie sesión para realizar la compra.');
        loginWithRedirect();
        return;
      }
      console.error('Error al realizar la compra:', error);
      alert('Hubo un error al realizar la compra. Por favor, inténtelo de nuevo más tarde.');
    }
  };

  const goBackToAdminFlights = () => {
    navigate('/adminflights');
  };

  return (
    <main className="content">
      <div className="bg-container"></div>
      <div className="content-landing">
        <button onClick={goBackToAdminFlights}>
          Volver
        </button>

        <h1>Bienvenido a <span className="name-landing">Admin Flight Purchase</span></h1>
        <div className="translucent-grey-rounded">
          <h2>Detalles del Vuelo</h2>
          <h3>Aeropuerto de Salida: {currentTicket ? currentTicket.departure_airport_id : ''}</h3>
          <h3>Aeropuerto de Llegada: {currentTicket ? currentTicket.arrival_airport_id : ''}</h3>
          <h3>Hora de Salida: {currentTicket ? new Date(currentTicket.time_departure).toLocaleString() : ''}</h3>
          <label htmlFor="optionSelector">Elija una opción:</label>
          <select id="optionSelector" name="option" onChange={handleQuantityChange}>
            {/* Options up to the max between four and the quanity of seats available */}
            {Array.from({ length: Math.min(4, currentTicket ? currentTicket.seats_available : 0) }, (v, k) => k + 1).map((option) => (
              <option key={option} value={option}>{option} ticket(s)</option>
            ))}
          </select>
          <h4>Precio Total: {currentTicket ? currentTicket.price * quantity : 0} {currentTicket ? currentTicket.currency : ''}</h4>

          <label>Comprar Ticket(s):</label>
          <button onClick={handleClick}>
            Comprar Ticket
          </button>
          <div>
            <label htmlFor="discountSelector">Descuento (%):</label>
            <select
              id="discountSelector"
              value={discountPercentage}
              onChange={(e) => setDiscountPercentage(parseInt(e.target.value, 10))}
            >
              <option value="1">1%</option>
              <option value="3">3%</option>
              <option value="5">5%</option>
              <option value="8">8%</option>
              <option value="10">10%</option>
            </select>
            <button onClick={applyDiscount}>
              Aplicar Descuento
            </button>
          </div>
        </div>
      </div>
    </main>
  );
}

export default AdminFlightPurchase;

