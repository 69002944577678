import {
    createBrowserRouter,
    RouterProvider,
    redirect
  } from 'react-router-dom';
import Layout from './pages/Layout';
import LandingPage from './pages/LandingPage/LandingPage';
import LoginButton from './profile/Login';
import LogOutButton from './profile/Logout';
import Profile from './profile/Profile';
import FlightPurchase from './pages/FlightPurchase/FlightPurchase';
import FlightSearch from './pages/FlightSearch/FlightSearch';
import MyTickets from './pages/MyTickets/MyTickets';
import ConfirmPurchase from './pages/TicketPurchase/ConfirmPurchase';
import PurchaseComplete from './pages/TicketPurchase/PurchaseComplete';
import TopFlightRecommendations from './pages/TopFlightRecommendations/TopFlightRecommendations';
import AdminFlightPurchase from './pages/FlightPurchase/AdminFlightPurchase';
import AdminFlights from './pages/AdminFlightSearch/AdminFlightSearch';
import Auctions from './pages/Auctions/Auctions';

  
  function Router() {
    const router = createBrowserRouter([
      {
        path: '/',
        element: <Layout />,
        children: [
          {
            path: '',
            element: <LandingPage />
          },
          {
            path: 'login',
            element: <LoginButton />
          },
          {
            path: 'logout',
            element: <LogOutButton />
          },
          {
            path: 'purchase/:id',
            element: <FlightPurchase />
          },
          {
            path: 'adminpurchase/:id',
            element: <AdminFlightPurchase />
          },
          {
            path: 'search',
            element: <FlightSearch />
          },
          { path: 'profile',
          element: < Profile />
          },
          { path: 'mytickets',
          element: < MyTickets />
          },
          { path: 'confirmarcompra',
          element: < ConfirmPurchase />},
          
          { path: 'compracompletada',
          element: < PurchaseComplete />},
          
          {
            path: 'topflightrecommendations',
            element: <TopFlightRecommendations />
          },
          {
            path: 'adminflights',
            element: <AdminFlights />
          },
          {
            path: 'auctions',
            element: <Auctions />
          }
        ]
      },
      {
        path: '*', 
        loader: () => {
          return redirect('/')
        }
      } 
    ])
  
    return (
      <RouterProvider router={router} />
    );
  }
  
  export default Router;
