import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './FlightSearch.css'
import { useNavigate } from 'react-router-dom';

const backendURL = process.env.REACT_APP_BACKEND_URL;
const CancelToken = axios.CancelToken;
let cancel;

function FlightSearch() {
  const navigate = useNavigate();
  const [flights, setFlights] = useState([]);
  const [filteredFlights, setFilteredFlights] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [departureAirport, setDepartureAirport] = useState('');
  const [arrivalAirport, setArrivalAirport] = useState('');
  const [departureDate, setDepartureDate] = useState('');

  useEffect(() => {
    const fetchFlights = async () => {
      try {
        if (cancel) {
          cancel('Request canceled');
        }
        console.log("Fetching flights");
        console.log(departureAirport, arrivalAirport, departureDate, page);
        const response = await axios.get(`${backendURL}/flights/`, {
          params: {
            departureAirport,
            arrivalAirport,
            departureDate,
            page
          },
          withCredentials: true,
          cancelToken: new CancelToken(function executor(c) {
            cancel = c;
          }),
        });
        if (response.data === "No hay información de vuelos disponible"){
          setFlights([]);
          setFilteredFlights([]);
          setLoading(false);
          return;
        }
        setFlights(response.data);
        setFilteredFlights(response.data);
        console.log(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching flights:', error);
        setLoading(false);
      }
    };
    fetchFlights();
  }, [departureAirport, arrivalAirport, departureDate, page,]);

  const handleFlightClick = (flightId) => {
    navigate(`/purchase/${flightId}`);
  };

  // const handleFilter = () => {
  //   console.log(departureAirport, arrivalAirport, departureDate);
  //   const filtered = flights.filter((flight) => {
  //     return (
  //       flight.departure_airport_id == departureAirport &&
  //       flight.arrival_airport_id == arrivalAirport &&
  //       new Date(flight.time_departure).toLocaleDateString() == departureDate
  //     );
  //   });
  //   setFilteredFlights(filtered);
  // };

  const handleFilter = () => {
    console.log(flights);
    
    const filtered = flights.filter((flight) => {
        // Verifica si los valores están vacíos y omite el filtro correspondiente si es así
        const departureFilter = !departureAirport || flight.departure_airport_id === departureAirport;
        const arrivalFilter = !arrivalAirport || flight.arrival_airport_id === arrivalAirport;
        const dateFilter = !departureDate || flight.time_departure === departureDate.replace('T', ' ');

        return departureFilter && arrivalFilter && dateFilter;
    });
    console.log(filtered);
    setFilteredFlights(filtered);
};
  const nextPage = () => {
    setPage(page + 1);
    if (filteredFlights.length === 0) {
      return;
    }
  }

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  }

  return (
    <main className="content">
      <div className="bg-container"></div>
      <div className="content-landing">
        <h1>Welcome to <span className="name-landing">Flight Search</span></h1>
        <div className="flights-list">
          <div className='filter-container'>
          <input
            type="text"
            placeholder="Departure Airport"
            value={departureAirport}
            onChange={(e) => setDepartureAirport(e.target.value)}
          />
          <input
            type="text"
            placeholder="Arrival Airport"
            value={arrivalAirport}
            onChange={(e) => setArrivalAirport(e.target.value)}
          />
          <input
            type="datetime-local"
            placeholder="Departure Date"
            value={departureDate}
            onChange={(e) => setDepartureDate(e.target.value)}
          />
          <button onClick={handleFilter}>Search</button>
          <button onClick={prevPage} >Previous Page</button>
          <button onClick={nextPage} >Next Page</button>
          </div>
          <div className="table-container">
            {filteredFlights.length === 0 ? (
              loading ? (
                <p>Loading...</p>
              ) : (
                <p>No flights available</p>
              )
            ) : (
              <table className="flights-table">
                <thead >
                  <tr>
                    <th>Departure Airport</th>
                    <th>Arrival Airport</th>
                    <th>Departure Time</th>
                    <th>Remaining Tickets</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredFlights.map((flight, index) => (
                    <tr
                      key={index}
                      onClick={() => handleFlightClick(flight.id)}
                      className={index % 2 === 0 ? 'black-row' : 'yellow-row'}
                    >
                      <td>{flight.departure_airport_id}</td>
                      <td>{flight.arrival_airport_id}</td>
                      <td>{new Date(flight.time_departure).toLocaleString()}</td>
                      <td>{flight.seats_available}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}

export default FlightSearch;
