import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './AdminFlightSearch.css'
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const backendURL = process.env.REACT_APP_BACKEND_URL;

function AdminFlights() {
  const [flights, setFlights] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [roles, setRoles] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const getAccessToken = async () => {
      if (isAuthenticated) {
        const accessToken = await getAccessTokenSilently();
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      }
    };
    getAccessToken();
  }, [isAuthenticated, getAccessTokenSilently]);

  // useEffect(() => {
  //   const fetchRoles = () => {
  //     const rolesNamespace = 'http://myapp.com/roles';
  //     const userRoles = user[rolesNamespace] || [];
  //     setRoles(userRoles);
  //   };
  //   // if (user) {
  //     fetchRoles();
  //   // }
  // }, [user]);

  const handleFlightClick = (flightId, seats_available) => {
    navigate(`/adminpurchase/${flightId}`, { state: { seats_available: seats_available } });
  };

  useEffect(() => {
    const fetchFlights = async () => {
      try {
        if (!user) {
          return;
        }
        setLoading(true);
        const response = await axios.get(`${backendURL}/admintickets`, {
          headers: { user: user.sub },
          withCredentials: true,
        });
        if (response.data === "No hay información de vuelos disponible") {
          setFlights([]);
          setLoading(false);
          return;
        }
        setFlights(response.data);
        console.log(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching flights:', error);
        setLoading(false);
      }
    };

    // if (user) {
      fetchFlights();
    // }
  }, [user]);

  return (
    <div className="my-tickets-container">
      <h1>Pasajes de Admin</h1>
      {loading ? (
        <div>Cargando...</div>
      ) : (
        <div>
          {flights.length == 0 ? (
            <div>El Admin no ha comprado tickets</div>
          ) : (
            <div className="table-container">
              <table className="flights-table">
                <thead>
                  <tr>
                    <th>Departure Airport</th>
                    <th>Arrival Airport</th>
                    <th>Departure Time</th>
                    <th>Remaining Tickets</th>
                  </tr>
                </thead>
                <tbody>
                  {flights.map((flight, index) => (
                    <tr
                      key={index}
                      onClick={() => handleFlightClick(flight[0].id, flight[0].seats_available)}
                      className={index % 2 === 0 ? 'black-row' : 'yellow-row'}
                    >
                      <td>{flight[0].departure_airport_id}</td>
                      <td>{flight[0].arrival_airport_id}</td>
                      <td>{new Date(flight[0].time_departure).toLocaleString()}</td>
                      <td>{flight[0].seats_available}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default AdminFlights;
