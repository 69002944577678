import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Auctions.css';
import { useAuth0 } from '@auth0/auth0-react';

const backendURL = process.env.REACT_APP_BACKEND_URL;

function Auctions() {

    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    // const [roles, setRoles] = useState([]);
    const [myAuctions, setMyAuctions] = useState([]);
    const [otherAuctions, setOtherAuctions] = useState([]);

    useEffect(() => {
        const getAccessToken = async () => {
        if (isAuthenticated) {
            const accessToken = await getAccessTokenSilently();
            axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        }
        };
        getAccessToken();
    }, [isAuthenticated, getAccessTokenSilently]);

    // useEffect(() => {
    //     const fetchRoles = () => {
    //     const rolesNamespace = 'http://myapp.com/roles';
    //     const userRoles = user[rolesNamespace] || [];
    //     setRoles(userRoles);
    //     };
    //     if (user) {
    //     fetchRoles();
    //     }
    // }, [user]);

    useEffect(() => {
        const fetchAuctions = async () => {
            try {
                const response = await axios.get(`${backendURL}/auctions/`, { headers: { user: user.sub},
                withCredentials: true,});
                // My auctions are the ones which the group_id is the same as the user's group_id
                // Other auctions are the ones which the group_id is different from the user's group_id
                const myAuctions = response.data.filter(auction => auction.group_id === 23);
                const otherAuctions = response.data.filter(auction => auction.group_id !== 23);
                setMyAuctions(myAuctions);
                setOtherAuctions(otherAuctions);
                console.log(otherAuctions);
            } catch (error) {
                console.error('Error al obtener las subastas:', error);
            }
        }
        if (user) {
            fetchAuctions();
        }
    }
    , [user]);

    return (
        <div className='main-content'>
            <div className='my-auctions'>
                <h1>
                    Mis Subastas
                </h1>
                <br />
                {
                    !Array.isArray(myAuctions)? 
                    (<h2>
                        No tienes subastas!
                    </h2>) : 
                    <ul>
                        {myAuctions.map((auction) => (
                            <li key={auction.id}>
                                <div>
                                    {/* class AuctionCreate(BaseModel):
                                                id: uuid.UUID
                                                proposal_id: uuid.UUID
                                                departure_airport_id: str
                                                arrival_airport_id: str
                                                datetime: str
                                                quantity: int
                                                group_id: int
                                                airline: str
                                                type: str*/}
                                    <strong>ID: </strong>{auction.id}
                                    <br />
                                    <strong>Propuesta ID: </strong>{auction.proposal_id}
                                    <br />
                                    <strong>Aerpuerto Salida: </strong>{auction.departure_airport_id}
                                    <br />
                                    <strong>Aerpuerto Llegada: </strong>{auction.arrival_airport_id}
                                    <br />
                                    <strong>Fecha: </strong>{auction.datetime}
                                    <br />
                                    <strong>Grupo ID: </strong>{auction.group_id}
                                    <br />
                                    <strong>Aerolínea: </strong>{auction.airline}
                                    <br />
                                    <strong>Tipo: </strong>{auction.type}
                                    <br />
                                </div>
                                <br />
                            </li>
                        ))}
                    </ul>
                }
            </div>
            <div className='other-auctions'>
                <h1>
                    Otras Subastas
                </h1>
                <br />
                {
                    // Check if otherAuctions is empty
                    !otherAuctions.length?
                    (<h2>
                        No hay subastas disponibles!
                    </h2>) :
                      <ul>
                      {myAuctions.map((auction) => (
                          <li key={auction.id}>
                              <div>
                                  {/* class AuctionCreate(BaseModel):
                                              id: uuid.UUID
                                              proposal_id: uuid.UUID
                                              departure_airport_id: str
                                              arrival_airport_id: str
                                              datetime: str
                                              quantity: int
                                              group_id: int
                                              airline: str
                                              type: str*/}
                                  <strong>ID: </strong>{auction.id}
                                  <br />
                                  <strong>Propuesta ID: </strong>{auction.proposal_id}
                                  <br />
                                  <strong>Aerpuerto Salida: </strong>{auction.departure_airport_id}
                                  <br />
                                  <strong>Aerpuerto Llegada: </strong>{auction.arrival_airport_id}
                                  <br />
                                  <strong>Fecha: </strong>{auction.datetime}
                                  <br />
                                  <strong>Grupo ID: </strong>{auction.group_id}
                                  <br />
                                  <strong>Aerolínea: </strong>{auction.airline}
                                  <br />
                                  <strong>Tipo: </strong>{auction.type}
                                  <br />
                              </div>
                              <br />
                          </li>
                      ))}
                  </ul>
                }
            </div>
        </div>
    );
}

export default Auctions;
