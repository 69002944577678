import React from "react";
import './LandingPage.css'

function LandingPage() {
  return (
    <main className="content">
        <div className="bg-container"></div>
        <div className="content-landing">
            <h1>Welcome to <span className="name-landing">Flight Booker</span></h1>
            <h5>Registrate o inicia sesión para solicitar y gestionar tus pasajes.</h5>
        </div>
    </main>
  )
}

export default LandingPage;