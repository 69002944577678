import React from "react";

function Footer() {
  return (
    <footer className="footer">
        Hecho por Angélica Gazitua, Ignacio Quera, Isidora Abumohor y Paula Hernández. 
    </footer>
  )
}

export default Footer;
