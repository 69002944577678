// import './Login.css';
// import './Logout.css';

// const LogOutButton = () => {
//     return (
//         <main className="content">
//         <div className="bg-container-logout"></div>
//         <div className="Logout">
//             <h1>¿Ya te vas?</h1>
//             <h2>¡Vuelve pronto!</h2>
//         <>
//         <button >Cerrar Sesión</button>
//         </>
//         </div>
//         </main>
//     )
// }

// export default LogOutButton;

import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
      Log Out
    </button>
  );
};

export default LogoutButton;