import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom'; // Asegúrate de importar Link desde react-router-dom

const TopFlightRecommendations = () => {
  const [recommendations, setRecommendations] = useState([]);
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();

  useEffect(() => {
    const fetchRecommendations = async () => {
      if (!user) {
        console.error('User is not authenticated');
        return;
      }

      try {
        const accessToken = await getAccessTokenSilently();
        console.log('Access Token:', accessToken);

        // // Realizar la solicitud POST para calcular las predicciones
        // await axios.post(`${process.env.REACT_APP_BACKEND_URL}/predictions/make_prediction`, datouser, {
        //   headers: { Authorization: `Bearer ${accessToken}`, user: user.sub }
        // });

        // Después de la solicitud POST, realizar la solicitud GET para obtener las predicciones
        console.log('Fetching recommendations');
        console.log('User:', user.sub)
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/predictions`, {
          headers: { Authorization: `Bearer ${accessToken}`, user: user.sub }
          ,
          withCredentials: true,
        });

        console.log('API Response:', response);
        setRecommendations(response.data);
      } catch (error) {
        console.error('Error fetching recommendations:', error);
        if (error.response) {
          console.error('Response Data:', error.response.data);
          console.error('Response Status:', error.response.status);
          console.error('Response Headers:', error.response.headers);
        }
      }
    };

    if (isAuthenticated) {
      fetchRecommendations();
    }
  }, [user, getAccessTokenSilently, isAuthenticated]);

  return (
    <div>
      <h1>My Top Flight Recommendations</h1>
      {recommendations.length > 0 ? (
        <ul>
          {recommendations.slice(-3).map((rec, index) => (
            <li key={index}>
              Flight ID: {rec.id}
              <Link to={`/purchase/${rec.id}`}>Ver Vuelo Recomendado</Link>
            </li>
          ))}
        </ul>
      ) : (
        <p>No recommendations available.</p>
      )}
    </div>
  );
};

export default TopFlightRecommendations;