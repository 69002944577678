import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const backendURL = process.env.REACT_APP_BACKEND_URL;

function MyFlights() {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);

  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const getAccessToken = async () => {
      if (isAuthenticated) {
        const accessToken = await getAccessTokenSilently();
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      }
    };
    getAccessToken();
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    const fetchRoles = () => {
      const rolesNamespace = 'http://myapp.com/roles';
      const userRoles = user[rolesNamespace] || [];
      setRoles(userRoles);
    };
    if (user) {
      fetchRoles();
    }
  }, [user]);

  const handleDelete  = async (ticketId) => {
    try {
      const response = await axios.delete(`${backendURL}/tickets/`, { headers: {ticket_id: ticketId, user: user.sub},
          withCredentials: true,});
      console.log(response);
      setTickets(response.data);
    } catch (error) {
      console.error('Error al eliminar el ticket:', error);
    }
  };

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        console.log(user.sub);
        const response = await axios.get(`${backendURL}/tickets/`, { headers: { user: user.sub},
        withCredentials: true,});
        setTickets(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error al obtener los tickets:', error);
        setLoading(false);
      }
    };
    if (user) {
      fetchTickets();
    }
  }, [user]);

  const handleDownload = async (ticket) => {
    try {
      const url = `${backendURL}/flights/${ticket.flight_id}`;
      const response = await axios.get(url);
      console.log(response);
      const pdfResponse = await axios.post(`${backendURL}/receipt/`, {
        ticket_id: ticket.id,
        passenger_name: user.name,
        flight_number: ticket.flight_id,
        departure: response.data.departure_airport_id,
        arrival: response.data.arrival_airport_id,
        seat: "1A",
        price: response.data.price * ticket.amount,
      });
      console.log(pdfResponse);
      const pdfUrl = pdfResponse.data['download_url'];
      window.open(pdfUrl, '_blank');
    } catch (error) {
      console.error('Error al descargar el ticket:', error);
    }
  };

  // const handlePublish = () => {
  //   console.log('Publish Ticket clicked');
  //   // Lógica para publicar el ticket
  // };
  const handlePublish = async (ticket) => {
    try {
      if (!roles.includes('admin')) {
        // Show error message because only admins can buy tickets for group 23
        alert('Only admins can publish tickets!.');
        return;
      }
      const url = `${backendURL}/flights/${ticket.flight_id}`;
      const response_flight_info = await axios.get(url);
      console.log(response_flight_info);
      console.log(response_flight_info.data[0]["airline"])
      console.log(ticket)
      const event_data = {
        auction_id: "",
        proposal_id: "",
        departure_airport: ticket.departure_airport_id,
        arrival_airport: ticket.arrival_airport_id,
        departure_time: ticket.time_departure,
        airline: response_flight_info.data[0]["airline"],  // Aquí debes proporcionar el nombre de la aerolínea
        quantity: ticket.amount,
        group_id: "23",
        type: "offer"
      };
      const response = await axios.post(`${backendURL}/auctions`, event_data);
      console.log(response)
      console.log('Ticket published successfully:', response.data);
    } catch (error) {
      console.error('Error al publicar el ticket:', error);
    }
  };

  return (
    <div className="my-tickets-container">
      <h1>Mis Pasajes</h1>
      {loading ? (
        <div>Cargando...</div>
      ) : (
        <div>
          {!Array.isArray(tickets) ? (
            <div>No tienes pasajes comprados</div>
          ) : (
            <ul>
              {Array.isArray(tickets) && tickets.map((ticket, index) => (
                <li key={index}>
                  <div>
                    <strong>Vuelo:</strong> {ticket.flight_id}<br />
                    <strong>Aeropuerto Salida:</strong> {ticket.departure_airport_id}<br />
                    <strong>Aeropuerto Llegada:</strong> {ticket.arrival_airport_id}<br />
                    <strong>Fecha Salida:</strong> {ticket.time_departure}<br />
                    <strong>Cantidad de asientos:</strong> {ticket.amount}<br />
                    <strong>Estado:</strong> {ticket.status}
                    <button onClick={() => handleDelete(ticket.id)}>Eliminar Ticket</button>
                    {ticket.status === 'valid' && (
                      <button onClick={() => handleDownload(ticket)}>Descargar Ticket</button>
                    )}
                    <button onClick={() => handlePublish(ticket)}>Subastar Ticket</button>
                  </div>
                  <br />
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
}

export default MyFlights;
